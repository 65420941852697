import { useLocation } from 'react-router-dom';

const useRightBarVisibility = () => {
  const { pathname } = useLocation(); 

  const listOfRoutes = ["/groups/:id","/userpage/:id"]; // list of routes where the left bar is visible not visible

  const isRouteMatched = listOfRoutes.some(route => {
    const regex = new RegExp(route.replace(/:\w+/g, '\\w+')); 
    return regex.test(pathname);
  });

  return !isRouteMatched; 
};

export default useRightBarVisibility;