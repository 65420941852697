import { useState } from "react"
import Logo from "../../assets/logo_symbol.png"
import "./resetPassword.scss"
import axios from "axios"
import { message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";


const ResetPassword = () => {
    const [password,setPassword]= useState("");
    const [confirmPassword,setConfirmPassword]= useState("")
    const [errorMessage,setErrorMessage]= useState("");
    const [isLoading,setIsLoading]= useState(false);
    const [isReset,setIsReset]= useState(false);
    const URL = process.env.REACT_APP_API_URL;
    const [messageApi, contextHolder] = message.useMessage();
    const isMobile = window.innerWidth <= 768;
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const navigate = useNavigate();
    
       const sendResetRequest= async ()=>{
            setIsLoading(true);
            try{
                await axios.put(URL+"/ssakti/users/user/validatePassword?"+ `password=${confirmPassword}&otp=${token}` )
                setIsLoading(false);
                setIsReset(true);
                messageApi.open({
                    type: 'success',
                    content: <RegistationSuccess/>,
                    className: 'custom-class',
                    style:isMobile ? successMobile : successWeb,
                  });
                  setTimeout(()=>{
                    navigate('/login');
                  },5000)
                  
                
            }
            catch (e){
                console.log(e.response.data.message)
                setErrorMessage(e?.response?.data?.message || "something went wrong")
                setIsLoading(false);
            }
           
        }
      

        const handleSubmit = (e)=>{
            e.preventDefault();

            if (password !== confirmPassword) {
                setErrorMessage("Passwords do not match.");
                return;
            }
            e.preventDefault()
            sendResetRequest();
            setPassword("");
            setConfirmPassword("");
            setErrorMessage("");
        }

        let successMobile = {
            marginTop: '10vh',
            height: "200px",
          }
        
          let successWeb ={
            marginTop: '10vh',
            // marginRight:'50vw',
            height: "200px",
          }
        
          const RegistationSuccess = ()=>{
            return (
              <div className="success">
                <h4>Password Reset Successfully</h4>
                <p>please login with your new password</p>
              </div>
            )
          }

    return (
        <>
        {contextHolder}
            <div className="resetPassword-container">
                <div className="main-container">
                        
                    <div className="img-container">
                        <img src={Logo} alt="" />
                    </div>
                    
    
                    <div className="form-container">
                        <form>
                            <div>
                                <label htmlFor="r-password">Password</label>
                                <input type='password' id="r-password" placeholder="Enter new password" value={password} onChange={(e)=>setPassword(e.target.value)}></input>

                                <label htmlFor="r-confirm-password">Confirm Password</label>
                                <input type='password' id="r-confirm-password" placeholder="Confirm Password" value={confirmPassword} onChange={(e)=>setConfirmPassword(e.target.value)}></input>

                                <span className={errorMessage.length>0 ? "r-error" : "r-success"}>{errorMessage}</span>
                            </div>
                            
                            <div>
                                {
                                    !isReset ?
                                    <button className="f-button sent-email" onClick={handleSubmit} disabled={isLoading}>{isLoading ? "sending request" :"Submit"}</button>
                                    :
                                    <button className="f-button success-email" disabled={true}>password reset Successfully</button>
                                }
                               
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword;