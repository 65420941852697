import React from 'react'
// import "./loadingPost.scss"
import { Skeleton} from 'antd';

function Loading() {
  return (
  
    <>
    <div style={{marginTop:"10px",display:"flex",gap:"20px"}}>
    <Skeleton.Avatar size="large" active large/>
    <div style={{display:"flex",flexDirection:"column",gap:"2px"}}>
    <Skeleton.Input style={{height:"20px"}} active width="100%" height={10}/>
    <Skeleton.Input width="100%" size='small' style={{height:"15px"}}  active/>
    </div>
    </div>
    <Skeleton.Image active style={{width:"100%",height:"18rem",marginTop:"0px"}}/>
    <div style={{display:"flex",flexDirection:"row",gap:"20px"}}>
    <Skeleton.Button size='small' style={{height:"30px"}} active width="10%" height={10}/>
    <Skeleton.Input style={{height:"30px"}}  active width="100%" height={10}/>
    <Skeleton.Input style={{height:"30px"}}  active width="100%" height={10}/>
    </div>
    </>
  
  )
}

export default Loading

