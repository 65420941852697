import "./messages.scss"
import React from "react";
import { useContext } from "react";
import FriendItemMessages from "./friendItemMessages";
import { useQuery } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { AuthContext } from "../../context/authContext";
import ChatWindow from "./ChatWindow";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link,useNavigate } from "react-router-dom";
import LoadingFriends from "../friendsListing/LoadingFriends";

const Messages = () => {
  const [selectedFriend, setSelectedFriend] = React.useState(null);
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const fetchFriends = async (id) => {
    const response = await makeRequest.get(`/ssakti/users/friendrequest/getFriendsList/${id}`);
    return response.data.friendListData;
  };

  const { data: friends, isLoading: friendsLoading, isError: friendsError } = useQuery(['friendsListMessages'], () => fetchFriends(currentUser.id), {
    enabled: !selectedFriend,
  });
  const handleFriendClick = (friend) => {
    setSelectedFriend(friend);
    // setMessages([]); // Clear messages when a new friend is selected
  };

  const handleGoBack = () => {
    navigate(-1);
};

  return (
    <div className="message-container">
      <div className={`friends-list ${selectedFriend ? 'active' : ""}`}>
        <div className="backButton">
      <ArrowBackIcon onClick={handleGoBack}/>
          <span>{currentUser.name}</span>
        </div>

        {
          friendsLoading ? (
            <LoadingFriends/>
          ) : friendsError ? (
            "No messages ."
          ) : friends.length > 0 ? (

            friends.map((friend) => {
              if (friend.status === "APPROVED") {
                return (
                  <FriendItemMessages
                    key={friend.userId} friend={friend}
                    handleFriendClick={handleFriendClick}
                    isActive={selectedFriend?.userId === friend.userId}
                  />)
              }
              else {
                return null;
              }
            })) : ("No friend requests found")

        }
      </div>
      <div className="chat-window">
        {selectedFriend ? (
          <ChatWindow setSelectedFriend={setSelectedFriend} selectedFriend={selectedFriend} currentUser={currentUser}/>
        ) : (
          <div className="select-friend">Select a friend to start chatting</div>
        )}
      </div>
    </div>
  );
};

export default Messages;