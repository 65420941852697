import React, { useState, useContext, useEffect } from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import Select from 'react-select';
import './pieChartStyle.scss';
import { DistrictContext } from '../../../../pages/adminDashboard/AdminDashboard';
import { makeRequest } from '../../../../axios';

const COLORS = ['#0088FE', '#ec4040', '#FF8042', '#FF0000', '#FFBB28'];

const PieChartComp = () => {
  const [selectedDistrict, setSelectedDistrict] = useState({ value: 'all', label: 'All' });
  const { districts } = useContext(DistrictContext);
  const [districtWiseData, setDistrictWiseData] = useState({});
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await makeRequest.get('/ssakti/admin/stats');
        setDistrictWiseData(response.data);
        console.log(response.data);
        // Initialize with 'All' data on load
        setFilteredData([
          { name: 'Posts', value: response.data.All.totalPosts },
          { name: 'Users', value: response.data.All.totalUsers },
          { name: 'Disputes', value: response.data.All.totalDisputes },
          { name: 'Groups', value: response.data.All.totalGroups },
          { name: 'Pages', value: response.data.All.totalPages },
        ]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleDistrictChange = (selectedOption) => {
    setSelectedDistrict(selectedOption);

    if (selectedOption.value == 'all') {
      // Show data for 'All'
      const allData = districtWiseData.all;
      const chartData = [
        { name: 'Posts', value: allData.totalPosts },
        { name: 'Users', value: allData.totalUsers },
        { name: 'Disputes', value: allData.totalDisputes },
        { name: 'Groups', value: allData.totalGroups },
        { name: 'Pages', value: allData.totalPages },
      ];
      setFilteredData(chartData);
    } else {
      // Show data for the selected district
      const selectedData = districtWiseData[selectedOption.label];
      const chartData = [
        { name: 'Posts', value: selectedData.totalPosts },
        { name: 'Users', value: selectedData.totalUsers },
        { name: 'Disputes', value: selectedData.totalDisputes },
        { name: 'Groups', value: selectedData.totalGroups },
        { name: 'Pages', value: selectedData.totalPages },
      ];
      setFilteredData(chartData);
    }
  };

  if (!districts || !districtWiseData) {
    return <div>Loading...</div>;
  }

  // Create options for react-select
  const districtOptions = districts.map((district) => ({
    value: district.districtId,
    label: district.districtName,
  }));

  // Adding the 'All' option at the start
  const allOptions = [ ...districtOptions];

  return (
    <div className="vendor-status-container">
      <div className="chart-section">
        <PieChart width={300} height={300}>
          <Pie
            data={filteredData.length ? filteredData : [{ name: 'No Data', value: 1 }]}
            cx={150}
            cy={150}
            innerRadius={80}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
            label
          >
            {filteredData.length
              ? filteredData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))
              : null}
          </Pie>
          <Tooltip />
        </PieChart>
      </div>

      <div className="filter-section">
        <h3>District Wise Data</h3>
        <div className="filter">
          <label>Select District</label>
          <Select
            value={selectedDistrict}
            onChange={handleDistrictChange}
            options={allOptions}
            className="district-select"
          />
        </div>

        {filteredData.length ? (
          <table className="status-table">
            <thead>
              <tr>
                <th>Status</th>
                <th>No. of Entries</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((status, index) => (
                <tr key={index}>
                  <td>{status.name}</td>
                  <td>{status.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No data available for this district.</p>
        )}
      </div>
    </div>
  );
};

export default PieChartComp;
