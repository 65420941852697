import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translation files
import en from '../locales/en.json';
import mr from '../locales/mr.json';

i18n
  .use(LanguageDetector)  // Automatically detects language
  .use(initReactI18next)  // Passes i18n instance to react-i18next
  .init({
    resources: {
      en: { translation: en },
      mr: { translation: mr },
    },
    fallbackLng: 'mr',  // Default language
    detection: {
      order: ['localStorage', 'navigator'],  // Detection mechanism
      caches: ['localStorage'],  // Cache language settings
    },
    interpolation: {
      escapeValue: false,  // React already escapes values
    },
  });

export default i18n;
