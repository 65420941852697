import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { DistrictContext } from '../../../pages/adminDashboard/AdminDashboard';

const FilterUserData = ({ onChangeYear, onChangeMonth, onChangeDistrict, selectedDistrict }) => {
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  const [month, setMonth] = useState('All');
  const apiUrl = process.env.REACT_APP_API_URL;
  const {districts}= useContext(DistrictContext);

  return (
    <div>
      <select value={year} onChange={(e) => { setYear(e.target.value); onChangeYear(e.target.value); }}>
        {/* Generate year options */}
        <option value={currentYear}>{currentYear}</option>
        <option value={currentYear - 1}>{currentYear - 1}</option>
        {/* Add more years if needed */}
      </select>
      <select value={month} onChange={(e) => { setMonth(e.target.value); onChangeMonth(e.target.value); }}>
        <option value="All">All</option>
        <option value="January">January</option>
        <option value="February">February</option>
        <option value="March">March</option>
        <option value="April">April</option>
        <option value="May">May</option>
        <option value="June">June</option>
        <option value="July">July</option>
        <option value="August">August</option>
        <option value="September">September</option>
        <option value="October">October</option>
        <option value="November">November</option>
        <option value="December">December</option>
      </select>
      <select value={selectedDistrict} onChange={(e) => { onChangeDistrict(e.target.value); }}>
        <option value="All">All Districts</option>
     {districts.map((dis)=>{
      return <>
      <option>{dis.districtName}</option>
      </>
     })}
      </select>
    </div>
  );
};

export default FilterUserData;
