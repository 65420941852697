import React, { useContext, useEffect, useState } from 'react'
import "./events.scss"
import { Link } from 'react-router-dom'
import { AuthContext } from "../../context/authContext";
import { makeRequest } from '../../axios';
import DeleteIcon from "@mui/icons-material/Delete";
import { notification } from 'antd';

function EventsUser() {

    const [events,setEvents] = useState([])
    const [districts, setDistricts] = useState([]);
    const { currentUser } = useContext(AuthContext);
    const [dist,setDist] = useState(currentUser.districtId);


    const getEventsByDistrict = async (dist) => {
        try {
          const response = await makeRequest.get(`/ssakti/users/event/getAllEvents/${currentUser.id}/${dist}`);
          console.log(response);
          if (response.status === 200) {
            setEvents(response.data.allEventDetails);
          } else {
            setEvents([]);
          }
        } catch (err) {
          console.log(err);
          setEvents([]);
        }
      };
    
      const fetchDistricts = async () => {
        try {
          const response = await makeRequest.get("/ssakti/users/district/getAllDistricts");
          setDistricts(response.data.getAllDistrictData);
        } catch (err) {
          console.error(err);
        }
      };

      useEffect(()=>{
          fetchDistricts();
      },[])
    
      useEffect(() => {
        console.log("this is useEffect")
        getEventsByDistrict(dist);
      }, [dist]);

      const deleteEvent=async(eventId)=>{
        try {
          const response = await makeRequest.delete(`/ssakti/users/event/delete/${currentUser.id}/${eventId}`);
          console.log(response);
          if (response.status === 200) {
            notification.success({
              message: response.data.message,
            });
            getEventsByDistrict(dist);
          }
        } catch (err) {
          console.log(err);
          notification.error({
            message: "Failed to delete event",
          });
        }
      }




  return (
    <div className='eventsPage'>
    <div className='head'>
        <h1>Events near you!</h1>
        <select onChange={(e)=>setDist(e.target.value)}>
            <option>Select district to see events</option>
            {
                districts && districts.map((item, index) => (
                    <option key={index} value={item.districtId}>{item.districtName}</option>
                ))
            }
        </select>
        <div style={{display:"flex",gap:"5px",alignItems:"center",justifyContent:"center"}}>
        <button> <Link to={'/createEvents'}>Create a Event</Link></button>
        <button> <Link to={'/yourEvents'}>Your Events</Link></button>
        </div>
    </div>
    <hr/>
    <div className='events'>
        {events && events.map((ad, index) => (
            <div className='event' key={index}>
            <Link to={`/events/${ad.eventId}`} className='image'>
                <img src={ad.postImageUrl} alt={ad.title} style={{borderRadius:'10px 10px 0 0'}} width={"300px"} height={"200px"}/>
            </Link>
            <div className='details' style={{padding:"10px",display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                <div style={{width:"90%",overflow:"hidden"}}>
                <span>{ad.startTime} - {ad.endTime}</span>
                <h3>{ad.postName}</h3>
                <p style={{fontSize:'12px',fontWeight:"bold"}}>{ad.eventDescription}</p>
                </div>
                <div style={{width:"10%"}}>
                {
                        currentUser.id == ad.hostUserId &&
                        <DeleteIcon onClick={()=>deleteEvent(ad.eventId)}/>
                      }
                </div>
            </div>
        </div>
        ))}

        {!events.length && <p>No events found in this district.</p>}

    </div>
</div>
  )
}

export default EventsUser
