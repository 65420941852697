import React, { useContext, useState } from 'react';
import { useQuery } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import CircularProgress from '@mui/material/CircularProgress';
import UserPageCard from '../../components/userPageCard/UserPageCard';
import { Pagination } from 'antd';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/authContext';
import "./explorePages.scss"

const ExplorePages = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(6);
  const { currentUser } = useContext(AuthContext);

  const fetchPages = async (page=1, size=6) => {
    const endpoint = `/ssakti/users/pages/getAllPages/${currentUser.id}`;
    try {
      const response = await makeRequest.get(endpoint, {
        params: { page: page - 1, size },
      });

      if (response.status === 204) {
        return {
          pagesDetail: [],
          totalElements: 0,
        };
      }

      return response.data;
    } catch (error) {
      console.error('Fetch error:', error);
      throw error;
    }
  };

  const { data, isLoading, isError } = useQuery(
    ['pagesList', currentPage, pageSize],
    () => fetchPages(currentPage, pageSize),
    {
      keepPreviousData: true,
    }
  );

  const onPageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  return (
    
    <div className="pages">
      <div className="head">
            <h1>Explore Pages</h1>
            <div>
               <button>
              <Link to="/userPage">Your Pages!</Link>
            </button>
            <button>
              <Link to="/createPage">Create Page!</Link>
            </button>
            </div>
          </div>
          <hr></hr>
      <div style={{display:'grid',gridTemplateColumns:"1fr 1fr 1fr"}}>
        {isError ? (
          <div>No Pages!</div>
        ) : isLoading ? (
          <div className="fetching">
            <CircularProgress />
          </div>
        ) : data?.pagesDetail.length === 0 ? (
          <div>No Pages!</div>
        ) : (
          data.pagesDetail.map(post => (
            <UserPageCard key={post.id} page={post} fetchPages={fetchPages} />
          ))
        )}
      
      </div>
      <div className='pagination-container'>
        <div>
          <Pagination
            showSizeChanger
            onChange={onPageChange}
            current={currentPage}
            pageSize={pageSize}
            total={data?.totalElements || 0}
            pageSizeOptions={[2, 4, 6, 8, 10]}
          />
          </div>
        </div>
    </div>
  );
};

export default ExplorePages;
