import { useState, createContext, useEffect} from "react";
import axios from "axios";
import "./adminDashboard.scss";
import UsersLineChart from "../../components/admin/analytics/usersLineChart";
import UserAndPostsData from "../../components/admin/analytics/UsersAndPostsData";
import PieChartComp from "../../components/admin/analytics/pieChart/PieChartComp";
export const DistrictContext = createContext();
const apiUrl = process.env.REACT_APP_API_URL;

const AdminDashboard = () => {
  const [districts, setDistricts] = useState([]);


  useEffect(() => {
    const fetchDistricts = async () => {
      try {
        const response = await axios.get(apiUrl + "/ssakti/users/district/getAllDistricts");
        console.log(response)
        setDistricts(response.data.getAllDistrictData);
      } catch (err) {
        console.error(err);
      }
    };

    fetchDistricts();
  }, []);
  
   
    return (
      <DistrictContext.Provider value={{ districts }}>
      <div className="adminDashboard">
      <h2>Dashboard</h2>
      <UserAndPostsData/>
      <div className="analyticsContainer">
      <UsersLineChart/>
      <PieChartComp/>
      </div>
      </div>
      </DistrictContext.Provider>
    )
  }
  
  export default AdminDashboard;