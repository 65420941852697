import React, { useContext, useEffect, useState } from 'react'
import './events.scss'
import { AuthContext } from '../../context/authContext'
import { makeRequest } from '../../axios'

function Ads() {

    const {currentUser} = useContext(AuthContext)
    const [allAds,setAllAds] = useState([])

    const getAllAdsByDistrict =async()=>{
        const response = await makeRequest.get(`/sskati/users/posts/post/${currentUser.districtId}`)
        console.log(response.data.postData)
        setAllAds(response.data.postData)
    }

    useEffect(()=>{
        getAllAdsByDistrict()
    },[])

  return (
    <div className='eventsPage'>
        <div className='head'>
            <h1>Advertisements in your district!</h1>
        </div>
        <div className='events'>
            {allAds.map((ad, index) => (
                <div className='event' key={index}>
                    <div className='image'>
                        <img src={ad.postImageUrl} alt={ad.title} style={{borderRadius:'10px 10px 0 0'}} width={"300px"}/>
                    </div>
                    <div className='details' style={{padding:"5px"}}>
                        <h3>{ad.postName}</h3>
                        <p style={{fontSize:'12px',fontWeight:"bold"}}>{ad.advertisementDescription}</p>
                    </div>
                </div>
            ))}

            {!allAds.length && <p>No events found in your district.</p>}

        </div>
    </div>
  )
}

export default Ads
