import "./share.scss";
import Image from "../../assets/img.png";
import { useContext, useState } from "react";
import { AuthContext } from "../../context/authContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import AWS from 'aws-sdk';
import { notification } from 'antd';
import heic2any from "heic2any";
import SendIcon from '@mui/icons-material/Send';
import { DarkModeContext } from "../../context/darkModeContext";
import { generateVideoThumbnail } from "../../helpers/thumbnailGenerator";
import { useTranslation } from "react-i18next";
import imageCompression from 'browser-image-compression';

const awsRegion = process.env.REACT_APP_AWS_REGION;
const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;

const Share = () => {
  const [file, setFile] = useState(null);
  const [desc, setDesc] = useState("");
  const [isUploading, setIsUploading] = useState(false); // Initialize uploading state
  const { toggle, darkMode } = useContext(DarkModeContext);
  const S3_BUCKET = 'streesakti';
  const [inputNameError, setInputNameError] = useState("");
  const { t } = useTranslation();


  AWS.config.update({
    accessKeyId: awsAccessKeyId,
    secretAccessKey: awsSecretAccessKey,
    region: awsRegion
  });

  const { darkmode } = useContext(DarkModeContext)
  const s3 = new AWS.S3();
  const { currentUser } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (formData) => {
      return makeRequest.post(`/sskati/users/posts/addPost/${currentUser.id}`, formData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["posts"]);
        queryClient.invalidateQueries(["lastestActivities"]);
        notification.success({
          message: 'Post uploaded successfully',
          description: 'Your post has been shared with everyone.',
        });
      },
      onSettled: () => {
        setIsUploading(false);
      }
    }
  );

  const handleInput = (e) => {
    setDesc(e.target.value);
    if (e.target.value.length > 0) {
      setInputNameError("");
    }
  };

  

  const handleClick = async () => {
    if (!desc && !file) {
      notification.error({
        message: 'No Image or No Description',
        description: 'You have to add an image or a description.',
      });
      return;
    }
    setIsUploading(true);
  
    let fileToUpload = file;
  
    if (file) {
      try {
        if (file.type.startsWith('image')) {
          // Compress image to be less than 150KB
          const imageOptions = {
            maxSizeMB: 0.10 , // 150KB
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };
          const compressedBlob = await imageCompression(file, imageOptions);

        // Convert the compressed Blob back to a File
        fileToUpload = new File([compressedBlob], file.name, {
          type: compressedBlob.type,
          lastModified: file.lastModified,
        });
          
        } else if (file.type.includes('video')) {
          // Check if the video size exceeds 10MB
          const maxVideoSizeMB = 15;
          const videoSizeMB = file.size / 1024 / 1024;
          if (videoSizeMB > maxVideoSizeMB) {
            notification.error({
              message: 'Video Too Large',
              description: `The video size is ${videoSizeMB.toFixed(2)} MB, which exceeds the 15MB limit.`,
            });
            setIsUploading(false);
            return;
          }
          fileToUpload = file;
        }
       
        // Upload the file
        const formData = new FormData();
        formData.append('postName', desc);
        formData.append('postImage', fileToUpload);
        formData.append('postType', file.type);
  
        mutation.mutate(formData);
      } catch (error) {
        console.log('Error during compression or upload:', error);
      } finally {
        setFile(null);
        setDesc('');
        setIsUploading(false);
      }
    }else{
      if(desc){
        try {
          const formData = new FormData();
          formData.append('postName', desc);       
    
          mutation.mutate(formData);
          
        } catch (error) {
          console.log('Error during Add post:', error);
        }finally {
          setFile(null);
          setDesc('');
          setIsUploading(false);
        }

      }
    }
  };
  

  // const handleClick = async () => {

  //   if(!desc && !file){
  //     notification.error({
  //       message: 'No Image or No Description',
  //       description: 'You have to add an image or a description.',
  //     });
  //     return;
  //   }

  //   setIsUploading(true); // Set uploading state to true when the upload starts

  //   let fileToUpload = file;
  //   let thumbnailUrl;
  //   if (file && file.type === "image/heic") {
  //     try {
  //       const convertedBlob = await heic2any({ blob: file, toType: "image/jpeg" });
  //       fileToUpload = new File([convertedBlob], file.name.replace(/\.[^/.]+$/, ".jpg"), { type: "image/jpeg" });
  //     } catch (error) {
  //       console.error("Error converting HEIC to JPEG:", error);
  //       notification.error({
  //         message: 'Conversion Failed',
  //         description: `Error converting your HEIC image: ${error.message}`,
  //       });
  //       setIsUploading(false);
  //       return;
  //     }
  //   } 
  //   if (file && file.type.includes("video")) {
  //     try {
  //       const thumbnailBlob = await generateVideoThumbnail(file); // Custom function to generate thumbnail
  //       const thumbnailParams = {
  //         Bucket: S3_BUCKET,
  //         Key: `posts/thumbnails/${file.name.replace(/\.[^/.]+$/, ".jpg")}`,
  //         Body: thumbnailBlob,
  //         ContentType: "image/jpeg",
  //       };
  //       const thumbnailData = await s3.upload(thumbnailParams).promise();
  //       thumbnailUrl = thumbnailData.Location;
  //     } catch (error) {

  //       return;
  //     }
  //   }

  //   let postImageUrl = null;
  //   if (fileToUpload) {
  //     const params = {
  //       Bucket: S3_BUCKET,
  //       Key: `posts/${fileToUpload.name}`,
  //       Body: fileToUpload,
  //     };

  //     try {       
  //       const data = await s3.upload(params).promise();
  //       postImageUrl = data.Location;
  //     } catch (error) {
  //       console.error("Error uploading file:", error);
  //       notification.error({
  //         message: 'Upload Failed',
  //         description: `Error uploading your file: ${error.message}`,
  //       });
  //       setIsUploading(false);
  //       return;
  //     }
  //   }

  //   mutation.mutate({
  //     postName: desc || null, 
  //     postImageUrl: postImageUrl || null,
  //     postType: file ? fileToUpload.type : null,
  //     videoThumbnailUrl: thumbnailUrl,
  //   });

  //   setFile(null);
  //   setDesc('');
  //   setIsUploading(false);
  // };


  return (
    <div className="share">
      <div className="container">
        <div className="top">
          <div className="left">
            <img src={currentUser.profilePic} alt="" />
            <textarea
              rows={"3"}
              type="text"
              placeholder={`${t("whatsOnYourMind")} ${currentUser.name}?`}
              onChange={handleInput}
              value={desc}
              disabled={isUploading} // Disable input during upload
            />
          </div>
          <div className="right">
            {file && file.type.startsWith('image/') && (
              <img className="file" alt="" src={URL.createObjectURL(file)} />
            )}
            {file && file.type.startsWith('video/') && (
              <video className="file" controls>
                <source src={URL.createObjectURL(file)} type={file.type} />
                Your browser does not support the video tag.
              </video>
            )}
            {!file && <p>{t("noFileSelected")}</p>} {/* Message when no file is selected */}
          </div>
        </div>
        {inputNameError && <span className="inputError">{inputNameError}</span>}
        <hr />
        <div className="bottom">
          <div className="left">
            <input
              type="file"
              id="file"
              accept="image/*,video/*"
              style={{ display: "none" }}
              onChange={(e) => setFile(e.target.files[0])}
              disabled={isUploading} // Disable file input during upload
            />
            <label htmlFor="file">
              <div className="item">
                <img src={Image} alt="" />
                <span>{t("addImageVideo")}</span>
              </div>
            </label>
          </div>
          <div className="right">
            <button onClick={handleClick} disabled={isUploading}>
              {isUploading ? t("sharing") : t("share")}
              {!isUploading && <SendIcon style={{ fontSize: "1.3rem" }} sx={{ color: darkmode ? "#000" : "#fff" }} />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Share;
