import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import image from "../../assets/register_image.jpg";
import user from "../../assets/user2.png";
import "./singlePage.scss";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ProfileTabsPages from "./ProfileTabsPages";
import { makeRequest } from "../../axios";
import AWS from "aws-sdk";
import { notification } from "antd";
import heic2any from "heic2any";
import SendIcon from "@mui/icons-material/Send";
import { AuthContext } from "../../context/authContext";
import { useMutation, useQueryClient, useInfiniteQuery, useQuery } from "@tanstack/react-query";
import Image from "../../assets/img.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Modal } from "antd";
import { nativeSelectClasses } from "@mui/material";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import imageCompression from 'browser-image-compression';

const awsRegion = process.env.REACT_APP_AWS_REGION;
const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;

function UserPage() {
  const params = useParams();
  // console.log(params)
  const [selectedTab, setSelectedTab] = useState("Posts");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {currentUser} = useContext(AuthContext)

  const [file, setFile] = useState(null);
  const [desc, setDesc] = useState("");
  const S3_BUCKET = "streesakti";
  const [inputNameError, setInputNameError] = useState("");
  const [seletedModalComponent, setSeletedModalComponent] = useState(null);
  // const [pageAbout,setpageAbout] = useState({})
  // const [pageMembers,setpageMembers] = useState([])
  // const [pendingFriends,setPendingFriends] = useState([])
  // const [posts, setPosts] = useState([]);

  const navigate = useNavigate();



  const getPageDetails = async ({ pageParam = 0 }) => {
    try {
      const response = await makeRequest.get(`/ssakti/users/pages/getPageDetails/${params.id}/${currentUser.id}`, {
        params: { page: pageParam, size: 4

         }, 
      });

      if (response.status === 204) {
        return {
          postDetails: [],
          currentPageMembers: [],
          pageAbout: {},
          hasNextPage: false,
          nextPageNo: undefined,
        };
      }

      const { postDetails, currentPageMembers, pageAbout, hasNextPage, nextPageNo } = response.data;
      return { postDetails, currentPageMembers, pageAbout, hasNextPage, nextPageNo };
    } catch (error) {
      console.error('Fetch error:', error);
      throw error;
    }
  };

  const { data, fetchNextPage, hasNextPage, isLoading, isFetching, status } = useInfiniteQuery(
    ['pageDetails'],
    getPageDetails,
    {
      getNextPageParam: (lastPage) => lastPage.hasNextPage ? lastPage.nextPageNo : undefined,
      refetchOnWindowFocus: false,
    }
  );

  const posts = data?.pages?.flatMap(page => page.postDetails || []);
  const pageAbout = data?.pages[0]?.pageAbout || {};
  const pageMembers = data?.pages[0]?.currentPageMembers || [];

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop < document.documentElement.offsetHeight - 100 || isFetching) return;
      if (hasNextPage) fetchNextPage();
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [fetchNextPage, hasNextPage, isFetching]);


  AWS.config.update({
    accessKeyId: awsAccessKeyId,
    secretAccessKey: awsSecretAccessKey,
    region: awsRegion,
  });

  const s3 = new AWS.S3();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (newPost) => {
      return makeRequest.post(
        `/ssakti/users/pages/addPost/${pageAbout.adminId}/${params.id}`,
        newPost
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["pageDetails"]);
      //   queryClient.invalidateQueries(["lastestActivities"]);
        notification.success({
          message: "Post uploaded successfully",
          description: "Your post has been shared with everyone.",
        });
      },
    }
  );

  const handleInput = (e) => {
    setDesc(e.target.value);
    if (e.target.value.length > 0) {
      setInputNameError("");
    }
  };

  const handleClick = async () => {
    if (desc.trim() === "") {
      setInputNameError("Description is required");
      return;
    }

    let fileToUpload = file;
    if (file && file.type === "image/heic") {
      try {
        const convertedBlob = await heic2any({
          blob: file,
          toType: "image/jpeg",
        });
        fileToUpload = new File(
          [convertedBlob],
          file.name.replace(/\.[^/.]+$/, ".jpg"),
          { type: "image/jpeg" }
        );
      } catch (error) {
        console.error("Error converting HEIC to JPEG:", error);
        notification.error({
          message: "Conversion Failed",
          description: `There was an error converting your HEIC image: ${error.message}`,
        });
        return;
      }
    }

    let postImageUrl = null;
    if (file) {
      const params = {
        Bucket: S3_BUCKET,
        Key: `posts/${fileToUpload.name}`,
        Body: fileToUpload,
      };

      try {
        const data = await s3.upload(params).promise();
        postImageUrl = data.Location;
      } catch (error) {
        console.error("Error uploading file:", error);
        notification.error({
          message: "Upload Failed",
          description: `There was an error uploading your file: ${error.message}`,
        });
        return;
      }
    }

    mutation.mutate({
      postName: desc,
      postImageUrl: postImageUrl,
      postType: file ? fileToUpload.type : null,
    });

    setFile(null);
    setDesc("");
  };

  const handleBack = () => {
    navigate(-1);
  };


  const handleModal = (component) => {
    setIsModalVisible(true);
    setSeletedModalComponent(component)
  }

  let content;
  switch (seletedModalComponent){
    case "updatepage":
      content = <Updatepage pageAbout={pageAbout}  setIsModalVisible={setIsModalVisible}/>
      break;
    case "pageSettings":
      content = <pageSettings/>
      break;
      case "EditCoverImage":
      content = <EditCoverImage pageAbout={pageAbout}  setIsModalVisible={setIsModalVisible}/>
      break;
  }
 

  const followPage=async()=>{
    try {
      const res = await makeRequest.post(`/ssakti/users/pages/followPage/${currentUser.id}/${params.id}`)
      console.log(res)
      if(res.status === 201){
        notification.success({
          message: "Followed successfully",
          description: res.data.message,
        });
        queryClient.invalidateQueries(["pageDetails"]);
      }
      else{
        notification.error({
          message: "Follow Failed",
          description: "You already followed the page.",
        });
      }
    } catch (error) {
      console.error("Error following page:", error);
      notification.error({
        message: "Follow Failed",
        description: "There was an error following the page.",
      });
    }
  }

  const unFollowPage=async()=>{
    console.log(pageAbout)
    try {
      const res = await makeRequest.delete(`/ssakti/users/pages/exitPage/${pageAbout.adminId}/${params.id}/${currentUser.id}`)
      console.log(res)
      if(res.status === 200){
        notification.success({
          message: "Unfollowed successfully",
          description: "You have successfully unfollowed the page.",
        });
        queryClient.invalidateQueries(["pageDetails"]);
      }
      else{
        notification.error({
          message: "Unfollow Failed",
          description: "You were not following the page.",
        });
      }
    } catch (error) {
      console.error("Error unfollowing page:", error);
      notification.error({
        message: "Unfollow Failed",
        description: "There was an error unfollowing the page.",
      });
    }
  }
  
  if (status === 'loading') return <p>Loading...</p>;
  if (status === 'error') {
    notification.error({
      message: 'Fetch Failed',
      description: `There was an error fetching the page details:}`,
    });
    return <p>Error loading data...</p>;
  }

  // Combine all pages of posts
  

  const handleLoadMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  console.log("about:", pageAbout )
  console.log("members:", pageMembers)


if(isLoading){
return (<p>loading</p>)
}
  return (
    <div className="grpSingle" style={{ height: "90vh", overflow: "scroll" }}>
       <div className="goBack">
        <button onClick={handleBack}>
          <ArrowBackIosIcon className="size-4" />
        </button>
          {
            currentUser.id === pageAbout.adminId && 
            <div onClick={()=>handleModal("EditCoverImage")}>
           <CameraAltIcon style={{fontSize:"2rem"}}/>
          </div>
          }
      </div>
      <div>
        <img src={pageAbout.pageCoverProfileImagePath} alt="page" />
      </div>
      <div className="detailsGrp">
        <div className="head">
          <img src={pageAbout.adminUserProfileImagePath} width={"100px"} height={"100px"} />
        </div>
        <div className="invite">
          <h1>{pageAbout.pageName}</h1>
          {
            pageAbout.adminId !== currentUser.id && (
              pageAbout.pageFollowed === true ? (
                <button className="button" onClick={unFollowPage}>UnFollow</button>
              ) : (
                <button className="button" onClick={followPage}>Follow</button>
              )
            )
          }
        </div>
        <div className="Tabs">
          <div className="profileTab">
            <div className="tabs">
              <button
                className={selectedTab === "Posts" ? "active" : ""}
                onClick={() => setSelectedTab("Posts")}
              >
                Posts
              </button>
              <button
                className={selectedTab === "Aboutpage" ? "active" : ""}
                onClick={() => setSelectedTab("Aboutpage")}
              >
                About
              </button>
              <button
                className={selectedTab === "Friends" ? "active" : ""}
                onClick={() => setSelectedTab("Friends")}
              >
                Members
              </button>
            </div>
          </div>
          {/* <div className="more">
            <button>
              <MoreVertIcon className="size-3" />
            </button>
          </div> */}
        </div>

        <div className="grpBody">
          <div className="leftBody">
            <ProfileTabsPages pageMembers={pageMembers} selectedTab={selectedTab} post={posts} userId={currentUser.id} data={pageAbout}/>
          </div>
          <div className="rightBody">
            {
              pageAbout.adminId !== currentUser.id ? null : (
                <p onClick={()=> handleModal("updatepage")}>Update Page</p>
              )
            }
          </div>
          <Modal
            visible={isModalVisible}
            // onOk={handleLogout}
            onCancel={() => setIsModalVisible(false)} 
            footer={null}
          >
         {content}
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default UserPage;

export const pageSettings=()=>{
  return (
    <div>
      <h1>page settings</h1>
    </div>
  )
}

export const Updatepage=({pageAbout,setIsModalVisible})=>{

  const [newpageName, setNewpageName] = useState(pageAbout.pageName)
  const [newpageDescription, setNewpageDescription] = useState(pageAbout.pageDescription)
  const [newFile,setNewFile] = useState(null)
  const {currentUser} = useContext(AuthContext)
  const queryClient = useQueryClient();
  

  const navigate= useNavigate()

  

  const handlepageUpdate=async()=>{
  
    if(!newpageName ||!newpageDescription){
      alert("Please fill all the fields")
      return;
    }
    else{
      
      let formdata = new FormData();
      formdata.append("name",newpageName)
      formdata.append("description",newpageDescription)
      
      const response = await makeRequest.put(`ssakti/users/pages/updatePage/${pageAbout.adminId}/${pageAbout.pagesId}`, formdata);
      console.log(response);
      if(response){
        notification.success({
          message: 'Page updated successfully',
        });
        // navigate(`/userpage`)
        setIsModalVisible(false);
        queryClient.invalidateQueries(["pageDetails"]);
      }
      else{
        notification.error({
          message: 'Error',
          description: "Failed to update page details.",
        });
      }
    }
  }

  return (
    <div>
      <h1 style={{margin:"20px"}}>Update Page</h1>
      <hr/>
      <div style={{display:"flex",flexDirection:'column',margin:'20px'}}>
      <label htmlFor="grpName" style={{fontWeight:"bold",fontSize:'1rem'}}>Change Page Name</label>
      <input id="grpName" style={{width:'80%',padding:'5px'}} name="newpageName" value={newpageName} onChange={(e)=>setNewpageName(e.target.value)} type="text" />
      </div>
      <div style={{display:"flex",flexDirection:'column',margin:'20px'}}>
      <label htmlFor="grpName" style={{fontWeight:"bold",fontSize:'1rem'}}>Change Page Description</label>
      <input id="grpName" style={{width:'80%',padding:'5px'}} name="newpageDescription" value={newpageDescription} onChange={(e)=>setNewpageDescription(e.target.value)} type="text" />
      </div>
      

      <button style={{backgroundColor:'#012e6b',color:"white",border:"none",padding:"10px",borderRadius:"5px",fontWeight:"700"}} onClick={handlepageUpdate} >Update</button>
    </div>
  )
}



export const EditCoverImage = ({ pageAbout, setIsModalVisible }) => {
  const queryClient = useQueryClient();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { currentUser } = useContext(AuthContext);

  const handleCoverUpdate = async () => {
    if (!file) {
      alert("Select your cover image first!");
      return;
    }

    setLoading(true);
    let imageGrp = null;

    try {
      const imageOptions = {
        maxSizeMB: 0.10, // 100KB
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      const compressedBlob = await imageCompression(file, imageOptions);

      imageGrp = new File([compressedBlob], file.name, {
        type: compressedBlob.type,
        lastModified: file.lastModified,
      });
      console.log(imageGrp)
      const formData = new FormData();
      formData.append("coverImage", imageGrp);

      const response = await makeRequest.put(`ssakti/users/pages/updatePage/${pageAbout.adminId}/${pageAbout.pagesId}`, formData);
      
      if (response) {
        notification.success({ message: 'Page updated successfully' });
        setIsModalVisible(false);
        queryClient.invalidateQueries(["pageDetails"]);
      } else {
        notification.error({ message: 'Error', description: "Failed to update page details." });
      }
    } catch (error) {
      notification.error({ message: 'Error', description: error.message || "An unexpected error occurred." });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Cleanup URL object if the file changes
    return () => {
      if (file) URL.revokeObjectURL(URL.createObjectURL(file));
    };
  }, [file]);

  return (
    <div>
      <h1 style={{ margin: "20px" }}>Update Cover Image</h1>
      <hr />
      <div style={{ display: "flex", flexDirection: 'column', margin: '20px' }}>
        <label htmlFor="coverImage" style={{ fontWeight: "bold", fontSize: '1rem' }}>Change Cover Photo</label>
        <input
          id="coverImage"
          style={{ width: '80%', padding: '5px' }}
          type="file"
          accept="image/*"
          onChange={(e) => setFile(e.target.files[0])}
        />
        {file && <img src={URL.createObjectURL(file)} alt="Preview" style={{ width: '100px', height: '100px' }} />}
      </div>
      <button
        style={{ backgroundColor: '#012e6b', color: "white", border: "none", padding: "10px", borderRadius: "5px", fontWeight: "700" }}
        onClick={handleCoverUpdate}
        disabled={loading}
      >
        {loading ? 'Updating...' : 'Update Cover Image'}
      </button>
    </div>
  );
};
