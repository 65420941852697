import React from 'react'
import { Skeleton } from 'antd';

function LoadingFriends() {
  return (

    <div style={{display:"flex",flexDirection:"column",gap:'50px'}}>
        <div style={{display:'flex',gap:"20px"}}>
      <Skeleton.Avatar size="large" active/>
      <div style={{display:'flex',flexDirection:"column",gap:"2px"}}>
      <Skeleton.Input style={{height:"15px"}} size="large" active/>
      <Skeleton.Input style={{height:"15px"}} size="small" active/>
      </div>
    </div>
    <div style={{display:'flex',gap:"20px"}}>
      <Skeleton.Avatar size="large" active/>
      <div style={{display:'flex',flexDirection:"column",gap:"2px"}}>
      <Skeleton.Input style={{height:"15px"}} size="large" active/>
      <Skeleton.Input style={{height:"15px"}} size="small" active/>
      </div>
    </div>
    </div>
  )
}

export default LoadingFriends
