// import { Parser } from "json2csv";
import Papa from "papaparse";

export const downloadCSV = ( fields, data) => {
    // const fields = ["id", "name", "email"];
    // const json2csvParser = new Parser({ fields });
    const filteredUsers = data.map(({ userFirstName, userLastName, userEmail,userCreatedAt,totalPostCount }) => ({
        userFirstName,
        userLastName,
        userEmail,
        userCreatedAt,
        totalPostCount
      }));
  
      const csv = Papa.unparse(filteredUsers);
    
    // Create a Blob and download the file
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "users.csv");
    link.click();
  };