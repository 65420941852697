import React from "react";
import { useNavigate } from "react-router-dom";
import "./searchItem.scss";

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const SearchItem = ({ friend, closeSearchResults }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/profile/${friend.userUUID}`);
    closeSearchResults(); // Close search results after navigating
  };

  return (
    <div className="searchItem" onClick={handleClick}>
      <div className="profilePic">
        <img src={friend.userProfileImagePath} alt={`${friend.userFirstName} ${friend.userLastName}'s profile`} />
      </div>
      <div className="info">
        <span style={{ fontWeight: "700" }}>
          {capitalize(friend.userFirstName)} {capitalize(friend.userLastName)}
        </span>
      </div>
    </div>
  );
};

export default SearchItem;
