import React from 'react'
import {  Skeleton} from 'antd';

function LoadingProfile() {
  return (
    
    <div style={{display:"flex",flexDirection:"column",justifyContent:'center',alignItems:'center'}}>
    <Skeleton.Image active style={{width:"65rem",height:"18rem",marginTop:"0px"}}/>
    <Skeleton.Input className='ske' active style={{width:"65rem",height:"18rem",marginTop:"10px"}}/>
    <Skeleton.Input className='ske' active style={{width:"65rem",height:"8rem",marginTop:"10px"}}/>
    </div>
  )
}

export default LoadingProfile
