import React, { useState } from 'react';
import { TextField, Button, CircularProgress, Typography, Container, Box, Paper } from '@mui/material';
import { chatWithGPT, generateImage } from './api'; // Import the API functions

const ChatAssistant = () => {
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false); // Loading state

  const handleSend = async () => {
    setLoading(true); // Start loading when user sends a message
    setResponse('');
    setImageUrl('');
    
    if (prompt.toLowerCase().includes('image')) {
      const image = await generateImage(prompt);
      setImageUrl(image);
    } else {
      const reply = await chatWithGPT(prompt);
      setResponse(reply);
    }

    setLoading(false); // Stop loading once the response is received
  };

  return (
    <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Sakhi AI
      </Typography>

      <Paper elevation={3} sx={{ padding: 2 }}>
        <Box component="form" noValidate autoComplete="off">
          <TextField
            label="Enter your prompt"
            fullWidth
            variant="outlined"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            multiline
            rows={4}
          />
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button variant="contained" color="primary" onClick={handleSend} disabled={loading}>
              Send
            </Button>
          </Box>
        </Box>

        {loading && (
          <Box display="flex" justifyContent="center" mt={2}>
            <CircularProgress />
          </Box>
        )}

        {response && (
          <Box mt={2}>
            <Typography variant="h6">Response:</Typography>
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
              {response}
            </Typography>
          </Box>
        )}

        {imageUrl && (
          <Box mt={2} display="flex" justifyContent="center">
            <img src={imageUrl} alt="Generated" style={{ maxWidth: '100%', borderRadius: '8px' }} />
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default ChatAssistant;
