import i18n from "../../context/i18n";
import "./languageToggler.scss"; 
import { useState } from "react";

const LanguageSwitcher =  () => {
  const [isMarathi, setIsMarathi] = useState(false);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);  // Correct usage of i18n.changeLanguage
    setIsMarathi(lang === 'mr');
  };

  const toggleLanguage = () => {
    const newLang = isMarathi ? 'en' : 'mr';
    handleLanguageChange(newLang);
  };

  return (
    <div className="language-switch">
      <div 
        className={`switch-container ${isMarathi ? 'active' : ''}`} 
        onClick={toggleLanguage}
      >
        <div className="switch">
          <span className="text">
            {isMarathi ? 'मराठी' : 'EN'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default LanguageSwitcher;