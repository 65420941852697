import "./userManager.scss";
import { makeRequest } from "../../axios";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { notification } from 'antd';
import moment from 'moment';
import { useState } from "react";
import { Pagination, Select } from 'antd';
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { downloadCSV } from "../../helpers/csvExport/downloadCSV.js";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

const UserManager = () => {
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [searchInput, setSearchInput] = useState("");
  const [triggerFetch, setTriggerFetch] = useState(true)
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const navigate = useNavigate();
  const { Option } = Select;

  // const { isLoading, isError, data: users } = useQuery(['getUserList'], () => {
  //     return makeRequest.get('ssakti/admin/user/getAllUser').then((res) => {
  //         return res.data.userDetails;
  //     });
  // });

  const { data: districts } = useQuery(['districts'], () => {
    return makeRequest.get('/ssakti/users/district/getAllDistricts').then((res) => res.data);
  }, 
  {
    refetchOnWindowFocus:false,
  }
);

  const fetchUsers = async (page = 1, size = 6) => {
    const endpoint = `ssakti/admin/user/getAllUserWithPagination`;
    try {
      const response = await makeRequest.get(endpoint, {
        params: { firstName: searchInput, districtId: selectedDistrict, page: page - 1, size },
      });

      if (response.status === 204) {
        return    {
          hasNextPage: false,
          totalPages: 0,
          pageSize: 0,
          nextPageNo: 0,
          currentPage: 0,
          totalElements: 0,
          userDetails: []
        };
      }
      setTriggerFetch(false)
      return response.data;
    
    } catch (error) {
      console.error('Fetch error:', error);
      throw error;
    }
  };

  const { data, isLoading, isError,isFetching } = useQuery(
    ['getUserList', currentPage, pageSize],
    () => fetchUsers(currentPage, pageSize),
    {
      keepPreviousData: true,
      refetchOnWindowFocus:false,
      enabled: triggerFetch,
      
    },
  );

  const handlePostClick = (userId) => {
    navigate('/admin/postsmanager', { state: { userId } });
  }

  const onPageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
    setTriggerFetch(true);
  };


  const mutation = useMutation((id) => {
    return makeRequest.delete("ssakti/admin/user/deleteUser/" + id);
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getUserList');
      notification.success({
        message: 'User Deleted Successfully',
        description: 'All the posts and comments have been deleted',
      });
    },
    onError: () => {
      notification.error({
        message: 'Deletion Failed',
        description: 'Failed to delete user. Please try again.',
      });
    },
  });

  const deleteUser = (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      mutation.mutate(userId);
    }
  };

  if (isError) {
    return <p>Error fetching users</p>;
  }

  if (isLoading || isFetching ) {
    return <p>Loading...</p>;
  }

  if(!data){
    return <p>No users found</p>;
  }


  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);
  };

  const handleDistrictChange = (value) => {
    setSelectedDistrict(value); // Update selected district
  };

  const handleSubmit = () => {
    setTriggerFetch(true)
  }


  const handleCsvDownload = () => {
    downloadCSV(['userFirstName', 'userLastName', 'userEmail'], data?.userDetails);
  }

  return (
    <div className="adminUserManager">
      <h2>User Manager</h2>

      <div className="top-bar-container">
        <div className="menu-left">
          <div className="search">
            <SearchOutlinedIcon
              aria-label="Search"
            // onClick={() => setIsSearchResultVisible(!isSearchResultVisible)}
            />
            <input
              type="text"
              placeholder="search"
              value={searchInput}
              onChange={handleSearchInputChange}
            // onFocus={() => setIsSearchResultVisible(true)}
            />
          </div>

          <div>
          <Select
            placeholder="Select District"
            value={selectedDistrict}
            onChange={handleDistrictChange}
            style={{ width: 200, marginLeft: '1rem' }}
          >
            <Option value={null}>All Districts</Option>
            {districts?.getAllDistrictData?.map(district => (
              <Option key={district.districtId} value={district.districtId}>{district.districtName}</Option>
            ))}
          </Select>
          </div>

          <div>
            <button className="submit-btn" onClick={handleSubmit} >Submit</button>
          </div>
        </div>
        <div className="menu-right">
          <button onClick={handleCsvDownload}>Download CSV</button>
        </div>
      </div>

      <div className="user-list">
        <div className="heading-container">
          <div className="image-heading"><h3>User Pic</h3></div>
          <div className="info-heading">
            <h3 className="info-heading-common">Name</h3>
            <h3 className="info-heading-common">Email</h3>
            <h3 className="info-heading-common">Total Posts</h3>
            <h3 className="info-heading-common">Created At</h3>
          </div>
          <div className="button-heading"><h3>Action</h3></div>
        </div>

        { data?.userDetails.length < 1 ? <p>no user found</p> : 
        data?.userDetails.map(user => (
          <div key={user.id} className="user-card">
            <div className="image-container">
              <img src={user.userProfileImagePath} alt={`${user.userFirstName} ${user.userLastName}`} />
            </div>
            <div className="user-info">
              <div className="name-container info-container-common"><h4>{user.userFirstName + " " + user.userLastName}</h4></div>
              <div className="email-container info-container-common"><p>{user.userEmail}</p></div>
              <div className="totalPost-container info-container-common post-count"><Tooltip title="see all post of user" aria-label="see posts" onClick={() => handlePostClick(user.userId)}>{user.totalPostCount}</Tooltip ></div>
              <div className="date-container info-container-common"><p>{moment(user.userCreatedAt).format('LL')}</p></div>
            </div>
            <div className="button-container">
              <button className="seePost-btn" onClick={() => handlePostClick(user.userId)}>See Posts</button>
              {/* <button className="delete-btn" onClick={() => deleteUser(user.userId)}>Delete</button> */}
            </div>
          </div>
        ))
        
        }

        {/* {data?.userDetails.map(user => (
          <div key={user.id} className="user-card">
            <div className="image-container">
              <img src={user.userProfileImagePath} alt={`${user.userFirstName} ${user.userLastName}`} />
            </div>
            <div className="user-info">
              <div className="name-container info-container-common"><h4>{user.userFirstName + " " + user.userLastName}</h4></div>
              <div className="email-container info-container-common"><p>{user.userEmail}</p></div>
              <div className="totalPost-container info-container-common post-count"><Tooltip title="see all post of user" aria-label="see posts" onClick={() => handlePostClick(user.userId)}>{user.totalPostCount}</Tooltip ></div>
              <div className="date-container info-container-common"><p>{moment(user.userCreatedAt).format('LL')}</p></div>
            </div>
            <div className="button-container">
              <button className="seePost-btn" onClick={() => handlePostClick(user.userId)}>See Posts</button>
              <button className="delete-btn" onClick={() => deleteUser(user.userId)}>Delete</button>
            </div>
          </div>
        ))} */}
      </div>
      <div>
        <Pagination
          showSizeChanger
          onChange={onPageChange}
          current={currentPage}
          pageSize={pageSize}
          total={data?.totalElements || 0}
          pageSizeOptions={[4, 8, 12, 16, 32, 64,10000]}
        />
      </div>
    </div>
  );
};

export default UserManager;
