import React from 'react'
import { Skeleton} from 'antd';

function LoadingActivities() {
  return (
    <div style={{display:"flex",gap:"20px",flexDirection:'column'}}>
      <div style={{display:'flex',gap:"20px"}}>
        <Skeleton.Avatar active/>
        <Skeleton.Input style={{width:"200%"}} active width="100%"/>
      </div>
      <div style={{display:'flex',gap:"20px"}}>
        <Skeleton.Avatar active/>
        <Skeleton.Input  style={{width:"200%"}} active width="200%"/>
      </div>
      <div style={{display:'flex',gap:"20px"}}>
        <Skeleton.Avatar active/>
        <Skeleton.Input  style={{width:"200%"}} active width="200%"/>
      </div>
    </div>
  )
}

export default LoadingActivities
