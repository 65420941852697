import React from 'react'
import './aboutGroup.scss';
import moment from "moment";

function AboutGroup({data}) {
    console.log("data from about group:",data)
  return (
    <div className='about'>
      <h1>About</h1>
      <hr/>
      <div className="user-details">
        <div className="item">
            <h4>NAME</h4>
            <p>{data.groupName}</p>
        </div>
        <div className="item">
            <h4>DESCRIPTION</h4>
            <p>{data.groupDescription}</p>
        </div>
      </div>
      <div className="user-details">
        <div className="item">
            <h4>ADMIN</h4>
            <p>{data.adminUserFirstName} {data.adminUserLastName}</p>
        </div>
        <div className="item">
            <h4>CREATED AT</h4>
            <p>{moment(data.groupCreatedAt).format('MMMM Do YYYY') }</p>
        </div>
      </div>
    </div>
  )
}

export default AboutGroup
