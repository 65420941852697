import {
    useState,
    useEffect,
    useRef,
    useCallback,
    useLayoutEffect,
  } from 'react';
  import { BiPlus, BiUser, BiSend, BiSolidUserCircle } from 'react-icons/bi';
  import { MdOutlineArrowLeft, MdOutlineArrowRight } from 'react-icons/md';
  import "./chatSakhi.css"
import { chatWithGPT,generateImage } from '../../components/ai/api';
import logo_symbol from "../../assets/logo_symbol.png"
import { type } from '@testing-library/user-event/dist/type';
const ChatSakhi = ()=>{
    const [text, setText] = useState('');
    const [responseData, setResponseData] = useState(null);
    const [previousChats, setPreviousChats] = useState([]);
    const [localChats, setLocalChats] = useState([]);
    const [currentTitle, setCurrentTitle] = useState(null);
    const [isResponseLoading, setIsResponseLoading] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [isShowSidebar, setIsShowSidebar] = useState(false);
    const scrollToLastItem = useRef(null);
    
    const createNewChat = () => {
      setResponseData(null);
      setText('');
      setCurrentTitle(null);
    };
  
    const backToHistoryPrompt = (uniqueTitle) => {
      setCurrentTitle(uniqueTitle);
      setResponseData(null);
      setText('');
    };
  
    const toggleSidebar = useCallback(() => {
      setIsShowSidebar((prev) => !prev);
    }, []);

    const checkPromptType= async (prompt)=>{
      const response = await chatWithGPT(prompt + "base on the prompt on left tell me what user want to do genrate an image or generate an text. as response want just type e.g 'image', 'text'");
      if(response.choices[0].message.content.includes('image')){
        return true;
      }
      else return false;
    }
  
    const submitHandler = async (e) => {
      e.preventDefault();
      // return setErrorText('My billing plan is gone because of many requests.');
      if (!text) return;
  
      setIsResponseLoading(true);
      setErrorText('');
  
      // const options = {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'Authorization': openAiToken,
      //   },
      //   body: JSON.stringify({
      //     message: text,
      //   }),
      // };

      // let isImageRequest = text.includes('image');
      let isImageRequest = await checkPromptType(text);
      let response;
      try {
        if(isImageRequest){
        response = await generateImage(text);
         
        }
        else{
          response = await chatWithGPT(text);
        }
  
        if (response?.status === 429) {
          return setErrorText('Too many requests, please try again later.');
        }
  
        // const data = await response.json();
  
        if (response.error) {
          setErrorText(response.error.message);
          setText('');
        } else {
          setErrorText(false);
        }
  
        if (!response.error) {
          setErrorText('');
          if(isImageRequest){
            setResponseData({
              content: response.data[0].url,
              role: "assistant",
              type: "image",
            })
          }else{
            setResponseData({
              content: response.choices[0].message.content,
              role: response.choices[0].message.role,
              type: "text",
            });
          }
          setTimeout(() => {
            scrollToLastItem.current?.lastElementChild?.scrollIntoView({
              behavior: 'smooth',
            });
          }, 1);
          setTimeout(() => {
            setText('');
          }, 2);
        }
      } catch (e) {
        setErrorText(e.message);
        console.error(e);
      } finally {
        setIsResponseLoading(false);
      }
    };
  
    useLayoutEffect(() => {
      const handleResize = () => {
        setIsShowSidebar(window.innerWidth <= 640);
      };
      handleResize();
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    useEffect(() => {
      const storedChats = localStorage.getItem('previousChats');
  
      if (storedChats) {
        setLocalChats(JSON.parse(storedChats));
      }
    }, []);
  
    useEffect(() => {
      if (!currentTitle && text && responseData) {
        setCurrentTitle(text);
      }
  
      if (currentTitle && text && responseData) {
        const newChat = {
          title: currentTitle,
          role: 'user',
          content: text,
        };
  
        const responseMessage = {
          title: currentTitle,
          role: responseData.role,
          content: responseData.content,
          type: responseData.type,
        };
  
        setPreviousChats((prevChats) => [...prevChats, newChat, responseMessage]);
        setLocalChats((prevChats) => [...prevChats, newChat, responseMessage]);
  
        const updatedChats = [...localChats, newChat, responseMessage];
        localStorage.setItem('previousChats', JSON.stringify(updatedChats));
      }
    }, [responseData, currentTitle]);
  
    const currentChat = (localChats || previousChats).filter(
      (prevChat) => prevChat.title === currentTitle
    );
  
    const uniqueTitles = Array.from(
      new Set(previousChats.map((prevChat) => prevChat.title).reverse())
    );
  
    const localUniqueTitles = Array.from(
      new Set(localChats.map((prevChat) => prevChat.title).reverse())
    ).filter((title) => !uniqueTitles.includes(title));
  
    return (
      <>
        <div className='container-ai-chat'>
          <section className={`sidebar ${isShowSidebar ? 'open' : ''}`}>
            <div className='sidebar-header' onClick={createNewChat} role='button'>
              <BiPlus size={20} />
              <button style={{color:"#ececf1"}}>New Chat</button>
            </div>
            <div className='sidebar-history'>
              {uniqueTitles.length > 0 && previousChats.length !== 0 && (
                <>
                  <p>Ongoing</p>
                  <ul>
                    {uniqueTitles?.map((uniqueTitle, idx) => {
                      const listItems = document.querySelectorAll('li');
  
                      listItems.forEach((item) => {
                        if (item.scrollWidth > item.clientWidth) {
                          item.classList.add('li-overflow-shadow');
                        }
                      });
  
                      return (
                        <li
                          key={idx}
                          onClick={() => backToHistoryPrompt(uniqueTitle)}
                        >
                          {uniqueTitle}
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
              {localUniqueTitles.length > 0 && localChats.length !== 0 && (
                <>
                  <p>Previous</p>
                  <ul>
                    {localUniqueTitles?.map((uniqueTitle, idx) => {
                      const listItems = document.querySelectorAll('li');
  
                      listItems.forEach((item) => {
                        if (item.scrollWidth > item.clientWidth) {
                          item.classList.add('li-overflow-shadow');
                        }
                      });
  
                      return (
                        <li
                          key={idx}
                          onClick={() => backToHistoryPrompt(uniqueTitle)}
                        >
                          {uniqueTitle}
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
            </div>
            <div className='sidebar-info'>
              <div className='sidebar-info-upgrade'>
                <BiUser size={20} />
                <p>Upgrade plan</p>
              </div>
              <div className='sidebar-info-user'>
                <BiSolidUserCircle size={20} />
                <p>User</p>
              </div>
            </div>
          </section>
  
          <section className='main'>
            {!currentTitle && (
              <div className='empty-chat-container'>
                <img
                  src={logo_symbol}
                  width={45}
                  height={45}
                  alt='SakhiAi'
                />
                <h1>Sakhi AI</h1>
                <h3>How can I help you today?</h3>
              </div>
            )}
  
            {isShowSidebar ? (
              <MdOutlineArrowRight
                className='burger'
                size={28.8}
                onClick={toggleSidebar}
              />
            ) : (
              <MdOutlineArrowLeft
                className='burger'
                size={28.8}
                onClick={toggleSidebar}
              />
            )}
            <div className='main-header'>
              <ul>
                {currentChat?.map((chatMsg, idx) => {
                  const isUser = chatMsg.role === 'user';
  
                  return (
                    <li key={idx} ref={scrollToLastItem}>
                      {isUser ? (
                        <div>
                          <BiSolidUserCircle size={28.8} />
                        </div>
                      ) : (
                        <img src={logo_symbol} alt='SakhiAi' />
                      )}
                      {isUser ? (
                        <div>
                          <p className='role-title'>You</p>
                          <p>{chatMsg.content}</p>
                        </div>
                      ) : (
                          <div >
                            <p className='role-title'>SakhiAI</p>
                            {chatMsg?.type == "image" ? (
                              <>
                              <img src={chatMsg.content} className='reponse-image'/>
                            </>
                            ) : (
                              <>
                                <p>
                                  {chatMsg.content}
                                </p>
                              </>
                          )}
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className='main-bottom'>
              {errorText && <p className='errorText'>{errorText}</p>}
              {errorText && (
                <p id='errorTextHint'>
                  *You can clone the repository and use your paid OpenAI API key
                  to make this work.
                </p>
              )}
              <form className='form-container' onSubmit={submitHandler}>
                <input
                  type='text'
                  placeholder='Send a message.'
                  spellCheck='false'
                  value={isResponseLoading ? 'Processing...' : text}
                  onChange={(e) => setText(e.target.value)}
                  readOnly={isResponseLoading}
                />
                {!isResponseLoading && (
                  <button type='submit'>
                    <BiSend size={20} />
                  </button>
                )}
              </form>
              <p>
                 AI can make mistakes. Consider checking important
                information.
              </p>
            </div>
          </section>
        </div>
      </>
    );
}

export default ChatSakhi;