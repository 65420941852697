import "./advertisements.scss";
import Image from "../../../assets/img.png";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/authContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../../axios";
import AWS from 'aws-sdk';
import { notification } from 'antd';
import heic2any from "heic2any";
import share from '../../../assets/share.png'
import axios from "axios";

const awsRegion = process.env.REACT_APP_AWS_REGION;
const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;

const Share = () => {

    const apiUrl = process.env.REACT_APP_API_URL;

    const [districts, setDistricts] = useState([]);
    useEffect(() => {
        const fetchDistricts = async () => {
          try {
            const response = await axios.get(apiUrl + "/ssakti/users/district/getAllDistricts");
            // console.log(response.data.getAllDistrictData);
            setDistricts(response.data.getAllDistrictData);
          } catch (err) {
            console.error(err);
          }
        };
    
        fetchDistricts();
      }, [apiUrl]);

  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [dist,setDist] = useState()
  const S3_BUCKET = 'streesakti';
  const [inputNameError, setInputNameError] = useState("");

  AWS.config.update({
    accessKeyId: awsAccessKeyId,
    secretAccessKey: awsSecretAccessKey,
    region: awsRegion
  });

  const s3 = new AWS.S3();
  const { currentUser } = useContext(AuthContext);
  // console.log(currentUser.id)
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (newAd) => {
      return makeRequest.post(`/ssakti/admin/posts/event/${dist}/${currentUser.id}`, newAd);
    },
    {
      onSuccess: () => {
        notification.success({
          message: 'Advertisement uploaded successfully',
          description: 'Your advertisement has been shared with everyone.',
        });
      },
    }
  );

  const handleInput = (e) => {
    
    setDesc(e.target.value);
    if (desc.length > 0) {
      setInputNameError("");
    }
  };

  const handleClick = async () => {
    if (desc.trim() === "") {
      setInputNameError("Description for Image/Video is required");
      return;
    }
    if (!file) {
      console.log("No file selected");
      return;
    }

    let fileToUpload = file;
    if (file.type === "image/heic") {
      try {
        const convertedBlob = await heic2any({ blob: file, toType: "image/jpeg" });
        fileToUpload = new File([convertedBlob], file.name.replace(/\.[^/.]+$/, ".jpg"), { type: "image/jpeg" });
      } catch (error) {
        console.error("Error converting HEIC to JPEG:", error);
        notification.error({
          message: 'Conversion Failed',
          description: `There was an error converting your HEIC image: ${error.message}`,
        });
        return;
      }
    }

    const params = {
      Bucket: S3_BUCKET,
      Key: `advertisements/${fileToUpload.name}`,
      Body: fileToUpload,
    };

    try {
      const data = await s3.upload(params).promise();

      mutation.mutate({
        postName: title,
        advertisementDescription:desc,
        postImageUrl: data.Location,
        postType: fileToUpload.type, // Pass the file type to the backend
        videoThumbnailUrl: null
      });

      setFile(null);
      setDesc('');
      setDist('')
      setTitle('')

    } catch (error) {
      console.error("Error uploading file:", error);
      notification.error({
        message: 'Upload Failed',
        description: `There was an error uploading your file: ${error.message}`,
      });
    }
  };
  

  return (
    <div className="shareAds">
      <h1 style={{textAlign:'center'}}>Add advertisements</h1>
      <div className="container">
        <div className="top">
          <div className="left">
            <img src={currentUser.profilePic} alt="" />
            <div className="enter">
            <input placeholder="Title of the advertisements" value={title} name="title" onChange={(e)=>setTitle(e.target.value)}/>
            <textarea
            // resi
              rows={"3"}
              type="text"
              placeholder={`Describe your advertisement ${currentUser.name}?`}
              onChange={handleInput}
              value={desc}
            />
            </div>
          </div>
          <div className="right">
            {file && file.type.startsWith('image/') && (
              <img className="file" alt="" src={URL.createObjectURL(file)} />
            )}
            {file && file.type.startsWith('video/') && (
              <video className="file" controls>
                <source src={URL.createObjectURL(file)} type={file.type} />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        </div>
        {inputNameError && <span className="inputError">{inputNameError}</span>}
        <hr />
        <div className="bottom">
          <div className="left">
            <input
              type="file"
              id="file"
              accept="image/*,video/*"  // Accept only images and videos
              style={{ display: "none" }}
              onChange={(e) => setFile(e.target.files[0])}
            />
            <label htmlFor="file">
              <div className="item">
                <img src={Image} alt="" />
                <span>Add Image/Video</span>
              </div>
            </label>
          </div>
          <div className="center">
          <select
                  name="district"
                  value={dist}
                  onChange={(e)=>setDist(e.target.value)}
                >
                  <option value="">Select District</option>
                  {districts.map((district) => (
                    <option key={district.districtId} value={district.districtId}>
                      {district.districtName}
                    </option>
                  ))}
                </select>
          </div>
          <div className="right">
            <button onClick={handleClick}>Share<img src={share} width={"18px"}/></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Share;
