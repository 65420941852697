import axios from 'axios';

const API_KEY = "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";

// ChatGPT API call for text generation
export const chatWithGPT = async (prompt) => {
  const response = await axios.post(
    'https://api.openai.com/v1/chat/completions',
    {
      model: 'gpt-4',  // or 'gpt-3.5-turbo' depending on your usage
      messages: [{ role: 'user', content: prompt }],
    },
    {
      headers: {
        Authorization: `Bearer ${API_KEY}`,
        'Content-Type': 'application/json',
      },
    }
  );
  return response.data;
  // return response.data.choices[0].message.content;
};


// DALL-E API call for image generation
export const generateImage = async (prompt) => {
    const response = await axios.post(
      'https://api.openai.com/v1/images/generations',
      {
        prompt: prompt,
        n: 1,
        size: '1024x1024',
      },
      {
        headers: {
          Authorization: `Bearer ${API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  };
  